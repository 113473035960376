import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { LocaleDatePipe } from '@app/pipes/locale-date.pipe';
import { AbsenteeDay, EnhancedAbsenceModel } from '@app/reducers/orm/absence/absence.model';
import { DatePipe } from '@app/shared/sb-lib/calendar/pipes/date.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { BadgeIconComponent, IconComponent } from '@sb/ui';
import { clsx } from 'clsx';

import { AbsenceBadgeIconDirective } from '../absence-badge-icon.directive';

@Component({
  standalone: true,
  selector: 'absence-tooltip-item',
  imports: [
    CommonModule,
    TranslateModule,
    DatePipe,
    LocaleDatePipe,
    AbsenceBadgeIconDirective,
    IconComponent,
    BadgeIconComponent,
  ],
  templateUrl: './absence-tooltip-item.component.html',
})
export class AbsenceTooltipItemComponent implements OnChanges {
  public absenceDay: AbsenteeDay;

  @Input({ required: true })
  public absence: EnhancedAbsenceModel;

  @Input()
  public date: Date | string;

  @Input()
  public showName: boolean;

  @HostBinding('class')
  public classes = clsx('flex flex-col py-4 first-of-type:pt-0 last-of-type:pb-0');

  public constructor(private DatePipe: DatePipe) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['absence']) {
      this.absenceDay =
        this.absence.AbsenteeDay[this.DatePipe.transform(this.date ?? this.absence.startdate, 'yyyy-MM-dd')];
    }
  }
}
